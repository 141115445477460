<template>
  <div class="clearfix">
    <b-table
      striped
      small
      hover
      :class="config.editable ? '' : 'not-editable'"
      :items="manuscriptsData"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      @row-clicked="onRowClicked"
    >
      <template #cell(codeRepcrit)="data">
        <router-link
          :to="{
            name: 'ManuscriptDetails',
            params: {
              id: data.item._object.id,
              slug: data.item._object.codeRepcritNormalized,
            },
          }"
          v-html="data.item._object.codeRepcrit"
        >
        </router-link>
      </template>
      <template #cell(codeFull)="data">
        <span v-html="data.item.codeFull" />
      </template>
      <template #cell(folios)="data">
        <span v-html="data.item.folios" />
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(codeOld)="data">
        <span v-html="data.item.codeOld" />
      </template>
      <template #cell(commentStatus)="data">
        <span v-html="data.item.commentStatus" />
      </template>
      <template #cell(commentGeneral)="data">
        <span v-html="data.item.commentGeneral" />
      </template>
      <template #cell(dates)="data">
        <span v-html="data.item.dates" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link
            :to="{
              name: 'ManuscriptDetails',
              params: {
                id: data.item.action.id,
                slug: data.item.action.codeRepcritNormalized,
              },
            }"
          >
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode && (showTextManuscript || showEditionManuscript || showLinkManuscript)"
            @click="editRow($event, data.item.action.manuscript)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a
            href="#"
            v-if="editMode"
            @click="deleteRow($event, data.item.action.id)"
            ><b-icon icon="trash"></b-icon
          ></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows == 1 ? "entry" : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
* >>> th {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
* >>> table.not-editable tbody tr {
  cursor: pointer;
}
</style>

<script>
import tdColUUID from "@/components/tdColUUID.vue";
import config from '@/config.js'

export default {
  name: "ManuscriptTable",
  props: [
    "manuscripts",
    "editMode",
    "showTotal",
    "showTextManuscript",
    "showEditionManuscript",
    "showLinkManuscript"
  ],
  data() {
    return {
      currentPage: 1,
      totalRows: this.manuscripts.length,
      perPage: 20,
      showTotalLable: this.showTotal ? this.showTotal : false,
      config: config,
    };
  },
  components: {
    tdColUUID,
  },
  methods: {
    onRowClicked (item, index, event) {
      console.log("AA", item, index, event);
    },
    btoa(string) {
      return window.btoa(string);
    },
    deleteRow(event, id) {
      event.preventDefault();
      this.$store.dispatch("addNotification", {
        type: "warning",
        text: `Manuscript deleted`,
      });
      this.$emit("delete", id);
    },
    editRow(event, manuscript) {
      event.preventDefault();
      this.$emit("edit", manuscript);
    },
  },
  computed: {
    manuscriptsData() {
      return this.manuscripts.map((manuscript, index) => {
        manuscript['_index'] = index;
        return {
          codeRepcrit: manuscript.codeRepcrit,
          ...(this.showTextManuscript && { folios: manuscript.folios }),
          ...(this.showTextManuscript && { foliosNum: manuscript.foliosNum }),
          ...(config.editable && {id: manuscript.id}),
          codeFull: manuscript.codeFull,
          codeOld: manuscript.codeOld,
          ...(this.showLinkManuscript && { comment: manuscript.comment }),
          commentStatus: manuscript.commentStatus,
          dates: manuscript.dates,
          commentGeneral: manuscript.commentGeneral,
          partOfManuscript: manuscript.partOfManuscript,
          ...(this.showEditionManuscript && { ordering: manuscript.ordering }),
          ...(this.showEditionManuscript && {
            sigleComment: manuscript.sigleComment,
          }),
          ...(this.showEditionManuscript && {
            isBaseManuscript: manuscript.isBaseManuscript,
          }),
          ...(config.editable && {
            action: {
              codeRepcritNormalized: manuscript.codeRepcritNormalized,
              id: manuscript.id,
              manuscript: manuscript,
            }
          }),
          _object: manuscript
        };
      });
    },
    fields() {
      return Object.keys(this.manuscriptsData[0]).filter(key => key.startsWith('_') === false);
    },
  },
};
</script>
