var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('b-table',{class:_vm.config.editable ? '' : 'not-editable',attrs:{"striped":"","small":"","hover":"","items":_vm.manuscriptsData,"per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields},on:{"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"cell(codeRepcrit)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'ManuscriptDetails',
          params: {
            id: data.item._object.id,
            slug: data.item._object.codeRepcritNormalized,
          },
        }},domProps:{"innerHTML":_vm._s(data.item._object.codeRepcrit)}})]}},{key:"cell(codeFull)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.codeFull)}})]}},{key:"cell(folios)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.folios)}})]}},{key:"cell(id)",fn:function(data){return [_c('tdColUUID',{attrs:{"value":data.item.id}})]}},{key:"cell(codeOld)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.codeOld)}})]}},{key:"cell(commentStatus)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.commentStatus)}})]}},{key:"cell(commentGeneral)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.commentGeneral)}})]}},{key:"cell(dates)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.dates)}})]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
            name: 'ManuscriptDetails',
            params: {
              id: data.item.action.id,
              slug: data.item.action.codeRepcritNormalized,
            },
          }}},[_c('b-icon',{attrs:{"icon":"link45deg"}})],1),(_vm.editMode && (_vm.showTextManuscript || _vm.showEditionManuscript || _vm.showLinkManuscript))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editRow($event, data.item.action.manuscript)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e(),(_vm.editMode)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteRow($event, data.item.action.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)]}}])}),(_vm.showTotalLable)?_c('b-col',{staticClass:"float-left",attrs:{"sm":"4","md":"3"}},[_vm._v(" Total "+_vm._s(_vm.totalRows)+" "+_vm._s(_vm.totalRows == 1 ? "entry" : "entries")+" in table ")]):_vm._e(),(_vm.totalRows > 10)?_c('b-col',{staticClass:"float-right",attrs:{"sm":"3","md":"2"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }