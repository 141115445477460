<template>
  <div class="writingtypeDetails">
    <div v-if="loading || !writingtype">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-if="writingtype" v-bind:title="writingtype.type" :pretitle="'Writing type details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.writingtype.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.writingtype.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.writingtype.modify') && $store.getters.checkRole('legam.bib.writingtype.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Type</h6>
                  <b-form-input
                    id="input-type"
                    v-model="writingtype.type"
                    placeholder="Type"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="writingtype.type"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="input-comment"
                    v-model="writingtype.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="writingtype.comment"></span>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Manuscripts <small>({{ writingtype.manuscripts.length }} {{ writingtype.manuscripts.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                          <b-icon icon="plus"></b-icon>
                          Add
                        </span>
                        <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                          <ManuscriptAutocomplete @select="addManuscript" />
                        </b-modal>
                        <ManuscriptTable
                          :showTotal="false"
                          :manuscripts="writingtype.manuscripts"
                          @delete="deleteManuscript"
                          :editMode="editMode"
                          v-if="writingtype.manuscripts.length"
                        />
                        <span v-else>No data</span>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
            <div class="mt-4" v-if="config.editable">
              <h6>Logs</h6>
              <ChangeLogsTable :objectType="'writingtype'" :objectId="writingtypeId" :key="'logs' + index" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ManuscriptTable from '@/components/Bibliography/ManuscriptTable.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import ManuscriptAutocomplete from '@/components/Bibliography/ManuscriptAutocomplete.vue'
  import config from '@/config.js'

  export default {
    name: 'WritingtypeDetails',
    data() {
      return {
        writingtypeId: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        indexSigleSource: 0,
        modalSigleSourceRel: null,
        indexLink: 0,
        modalLink: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      ManuscriptTable,
      ManuscriptAutocomplete,
      ChangeLogsTable,
    },
    computed: {
      ...mapState({
        writingtype: state => state.writingtype.writingtype
      })
    },
    mounted() {
      this.$store.dispatch('loadWritingtype', this.writingtypeId)
    },
    watch: {
      'id'() {
        this.loading = true
        this.$store.dispatch('loadWritingtype', this.writingtypeId)
      },
      'writingtype'() {
        this.loading = false
      }
    },
    methods: {
      addManuscript(manuscript){
        this.$bvModal.hide('modal-add-manuscript')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Manuscript added`
        })
        this.writingtype.manuscripts.push(manuscript)
      },
      deleteManuscript(manuscriptId){
        this.writingtype.manuscripts = this.writingtype.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },

      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadWritingtype', this.writingtypeId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveWritingtype', this.writingtype).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteWritingtype', this.writingtype).then(() => {
              router.push('/writingtypes')
            })
          }
        })
      }
    }
  }
</script>
